<template>
    <div class="quote-container">
      <div class="vertical-line"></div>
      <blockquote>
        Milán 44 es una de las propiedades más icónicas de la Colonia Juárez.<br>
        ¿Te interesa rentar un espacio en Milán 44? 
      </blockquote>
      <cite>NYD HOLDINGS</cite>
    </div>
  </template>
  
  <style scoped>
  .quote-container {
    position: relative;
    font-family: Arial, sans-serif;
    margin-left: 20px;
    margin-top: 15%;
    margin-bottom: 15%;
  }
  
  .vertical-line {
    position: absolute;
    left: -20px;
    top: 0;
    bottom: 0;
    width: 5px;
    background-color: black;
  }
  
  blockquote {
    
    text-align: center;
    color: #000000;
    font-family: "Arial", Sans-serif;
    font-size: 28px;
    font-weight: 400;
    line-height: 1.6em;
    letter-spacing: 0.9px;
  }
  
  /* blockquote:before {
    content: open-quote;
  }
  
  blockquote:after {
    content: close-quote;
  } */
  
  cite {
    display: block;
    text-align: center;
    margin-top: 10px;
    font-style: normal;
    font-weight: bold;
  }
  </style>
  