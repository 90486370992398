<template>
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-12 text-container  mt-3">
                <h2 class="title">M2 Rentables: <span class="data">1,056.00 m2</span></h2>
                <p class="subtitle">Uso: <span class="data">Comercial y Oficinas</span></p>
                <p class="subtitle">Unidades:<span class="data"> 4 locales comerciales y espacios para oficina</span></p>
                <p class="subtitle">Ubicación: <span class="data">Col. Juárez</span></p>
                <h3 class="awards-title">Premios:</h3>
                <ul class="awards-list">
                    <li>Premios Archmarathon, Diseño de Interiores, 2018</li>
                    <li>Ganador de la IX Bienal Panamericana de Arquitectura de Quito, 2016</li>
                    <li>Medalla de plata de la XV Bienal de Arquitectura Mexicana, 2016</li>
                </ul>
                <p class="authors"><a href="https://franciscopardo.mx/">Francisco Pardo</a> y Julio Amezcua (AT103)</p>
            </div>
            <div class="col-md-6 col-12 mt-3">
                <img  class="imgMilan44" :src="require('@/assets/img/INFO_Milan44.webp')" alt="Edificio Milan 44">
            </div>
        </div>
    </div>
</template>
  
  <script>
  
  export default ({
    name: 'detallesMilan',
    data() {
      return {
      }
    },
    components: {
    },
  })
  </script>
  <style scoped>
  .container {
    font-family: Arial, sans-serif;
    background-color: #b0b1b2;
    padding: 20px;
  }
  
  .title {
    font-size: 18px;
    color: #000;
  }
  
  .subtitle {
    font-size: 16px;
    color: #333;
  }
  
  .data {
    font-weight: bold;
  }
  
  .awards-title {
    font-size: 16px;
    color: #000;
    margin-top: 10px;
  }
  
  .awards-list {
    list-style: none;
    padding: 0;
    font-size: 14px;
    color: #333;
  }
  
  .awards-list li::before {
    content: '– ';
    color: #000;
  }
  
  .authors {
    font-size: 14px;
    color: #000;
    font-style: italic;
  }
  .text-container {
  border-left: 5px solid #000; /* Ajusta el grosor de la línea según sea necesario */
  padding-left: 20px; /* Añade un poco de espacio entre la línea y el texto */
  /* Resto de tus estilos */
}
.imgMilan44{
    width: 95%;
}
  </style>
  