<template>
    <div class="container">
        <div class="row">
        <h2>Contacto</h2>
            <div class="col-md-6 col-12 text-container  mt-3">
                <googleMaps />
            </div>
            <div class="col-md-6 col-12 mt-3">
                <formularioMIlan />
            </div>
        </div>
    </div>
</template>
  
  <script>
  import googleMaps from './googleMaps.vue';
  import formularioMIlan from './formularioMIlan.vue';
  export default ({
    name: 'contactoMilan',
    data() {
      return {
      }
    },
    components: {
        googleMaps,
        formularioMIlan
    },
  })
  </script>
  <style scoped>
  </style>
  