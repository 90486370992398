<template>
    <div class="form-container">
        <div class="row">
            <div class="col-12">
                <div class='titulo-principal '>
                    <span class='color-negro'>MILÁN</span>
                    <span class='color-verde'> 44</span>
                </div>
            </div>
            <div class="col-12">
                <div class='titulo-direccion'>
                    <span class='color-gris'>COL. JUÁREZ</span>
                </div>
            </div>
        </div>
        <form @submit.prevent="submitForm" novalidate>
            <div class="form-group">
                <input type="text" v-model="form.nombre" placeholder="Nombre" required>
                <span v-if="errors.nombre" class="error-message">{{ errors.nombre }}</span>
            </div>
            <div class="form-group">
                <input type="text" v-model="form.apellido" placeholder="Apellido" required>
                <span v-if="errors.apellido" class="error-message">{{ errors.apellido }}</span>
            </div>
            <div class="form-group">
                <input type="email" v-model="form.correo" placeholder="Correo" required>
                <span v-if="errors.correo" class="error-message">{{ errors.correo }}</span>
            </div>
            <div class="form-group">
                <input type="tel" v-model="form.telefono" placeholder="Número de teléfono móvil" required
                    pattern="^\d{10}$">
                <span v-if="errors.telefono" class="error-message">{{ errors.telefono }}</span>
            </div>
            <input type="submit" value="Enviar">
        </form>
    </div>
</template>
  
<script>
import axios from 'axios';

export default {
    data() {
        return {
            form: {
                nombre: '',
                apellido: '',
                correo: '',
                telefono: ''
            },
            errors: {}
        };
    },
    methods: {
        validateForm() {
            this.errors = {}; // Reinicia los errores
            let valid = true;
            if (!this.form.nombre) {
                this.errors.nombre = 'Nombre faltante';
                valid = false;
            }
            if (!this.form.apellido) {
                this.errors.apellido = 'Apellido faltante';
                valid = false;
            }
            if (!this.form.correo.includes('@')) {
                this.errors.correo = 'Correo incorrecto';
                valid = false;
            }
            if (!this.form.telefono.match(/^\d{10}$/)) {
                this.errors.telefono = 'Teléfono incorrecto debe ser a 10 digitos';
                valid = false;
            }
            return valid;
        },
        submitForm() {
            if (this.validateForm()) {
                // Configura la cabecera de Content-Type para el envío de datos de formulario
                const config = {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                };
                // Utiliza URLSearchParams para manejar los datos del formulario
                const formData = new URLSearchParams();
                Object.keys(this.form).forEach(key => formData.append(key, this.form[key]));

                axios.post('send_email.php', formData, config)
                    .then(response => {
                        // Manejar la respuesta
                        console.log(response.data);
                        alert('Mensaje enviado con éxito.');
                    })
                    .catch(error => {
                        // Manejar el error
                        console.error(error);
                        alert('Hubo un error al enviar el mensaje.');
                    });
            }
        }
    }
};
</script>
  
<style>
.color-negro{
    color: #000000;
 }
 .color-verde{
    color:#D7E025
 }
 .color-gris{
    color: #414141;
 }
 .titulo-principal{
    font-family: "Arial", sans-serif;
    font-size: 30px;
    text-align: left;
    line-height: 1.1; /* Ajusta el espaciado entre líneas */
    margin-bottom: 0; /* Elimina el margen inferior para acercar al siguiente bloque de texto */
}
.form-container {
    max-width: 600px;
    /* Ajusta al ancho deseado */
    background-color: #b0b1b2;
    /* Color de fondo del contenedor del formulario */
    padding: 20px;
    /* Espaciado interno */
    border: 1px solid #b0b1b2;
    /* Borde del contenedor */
}

.form-group {
    margin-bottom: 20px;
    /* Espacio entre campos del formulario */
}

.form-group input[type="text"],
.form-group input[type="email"],
.form-group input[type="tel"] {
    width: 100%;
    /* Ancho completo dentro del contenedor */
    padding: 10px;
    /* Espaciado interno para el texto */
    margin: 5px 0;
    /* Margen para separar los input */
    border: 1px solid #ccc;
    /* Borde de los campos de texto */
}

input[type="submit"] {
    padding: 10px 20px;
    /* Espaciado interno para el botón */
    background-color: #ff7a59;
    /* Color de fondo para el botón */
    border: none;
    /* No border */
    color: white;
    /* Color de texto */
    cursor: pointer;
    /* Estilo del cursor como puntero */
    border-radius: 5px;
    /* Bordes redondeados del botón */
    margin-top: 10px;
    /* Espacio antes del botón */
}

input[type="submit"]:hover {
    background-color: #ff7a59;
    /* Color al pasar el mouse por encima */
}

.form-group span {
    color: red;
    /* Color de los mensajes de error */
    font-size: 0.8em;
    /* Tamaño de la fuente para los errores */
}
.error-message {
  color: red;
  font-size: 0.8rem;
  margin-top: 0.5rem;
  display: block; /* O como prefieras mostrarlo */
}
@media (max-width: 600px) {
    .form-container {
        padding: 10px;
        /* Menor espaciado interno para pantallas pequeñas */
    }
}</style>

  