<template>
    <div class=" fondo pb-5 pt-5">
        <h2 class='titulo-centro pb-3'>La colonia Juárez:</h2>
    <Carousel  :breakpoints="breakpoints" :wrap-around="true">
      <Slide v-for="slide in slides" :key="slide">
        <div class="carousel__item">
            <img class='img-carrusel-dos' :src="require('@/assets/img/galeriaDos/' + slide)" alt="slide" />
        </div>
      </Slide>
  
      <template #addons>
        <Navigation />
      </template>
    </Carousel>
</div>
</template>
  
  <script>
  import { defineComponent } from 'vue'
  import { Carousel, Navigation, Slide } from 'vue3-carousel'
  
  import 'vue3-carousel/dist/carousel.css'
  
  export default defineComponent({
    name: 'carruselDosMilan',
    data() {
      return {
        breakpoints: {
        640: {
          itemsToShow: 1,  // Muestra 1 imagen en pantallas hasta 640px
        },
        768: {
          itemsToShow: 2.5,  // Muestra 2 imágenes en pantallas hasta 768px
        },
        1024: {
          itemsToShow: 3.5,  // Muestra 3 imágenes en pantallas de 1024px en adelante
        },
      },
        wrapAround: true,
        slides: [
            'col_Juarez-10-500x500_11zon.webp',
            'col_Juarez-11-500x500_11zon.webp',
            'col_Juarez-12-500x500_11zon.webp',
            'col_Juarez-13-500x500_11zon.webp',
            'col_Juarez-14-500x500_11zon.webp',
            'col_Juarez-15-500x500_11zon.webp',
            'col_Juarez-16-500x500_11zon.webp',
            'col_Juarez-17-500x500_11zon.webp',
            'col_Juarez-18-500x500_11zon.webp',
            'col_Juarez-18-500x500_11zon.webp',
        ],
      }
    },
    components: {
      Carousel,
      Slide,
      Navigation,
    },
  })
  </script>
<style scoped>
    .fondo{
        background-color: #b0b1b2;
    }
    .img-carrusel-dos{
        width: 100%;
        height: 100%;
    }

</style>