<template>
  <div id="app">
    <font-awesome-icon :icon="['fas', 'bars']" class="menu-icon bars-btn" @click="toggleMenu" />
    <transition name="slide">
      <div class="menu" v-if="showMenu">
        <!-- Pon links de las secciones de Acerca de nosotros Proyectos Comunidad Oportunidades Contacto Idiomas -->
        <div class="menu" v-if="showMenu">
          <div class="menu-header">
            <font-awesome-icon :icon="['fas', 'xmark']" class="close-btn" @click="toggleMenu" />
        </div>
          <img src="../assets/NYDHOLDINGSWHITE.svg" alt="Logo" width="100px" height="100px">
          <hr style='color: aliceblue;'>
          <a href="#proyecto">Proyecto</a>
          <a href="#galeria">Galería</a>
          <a href="#detalles">Detalles</a>
          <a href="#contacto">Contacto</a>
          <a href="#contactodos">Enviar Correo</a>
        </div>

      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showMenu: false
    };
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
    }
  }
};
</script>
<style>
/* Estilos base para la animación */
.slide-enter-active, .slide-leave-active {
  transition: transform 0.3s ease;
}
.slide-enter-from, .slide-leave-to {
  transform: translateX(-100%);
}
.navbar {
  display: flex;
  align-items: center; /* Alinea verticalmente los ítems */
  padding-left: 10px; /* Espaciado desde el borde izquierdo */
}
/* Estilos para el menú */
.menu {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%; /* o el ancho que prefieras */
  background-color: #000; /* o el color de fondo que prefieras */
  color: #fff; /* o el color de texto que prefieras */
  padding: 20px;
  box-sizing: border-box;
  z-index: 1000; /* Para que se muestre encima de todo */
}
/* ...otros estilos... */
/* Estilos para los enlaces */
.menu a {
  display: block; /* Cada enlace ocupa su propia línea */
  color: #ffffff; /* Color de texto de los enlaces */
  padding: 15px 20px; /* Espaciado alrededor del texto */
  text-decoration: none; /* Elimina el subrayado de los enlaces */
  font-size: 1.5em; /* Tamaño de fuente más grande para los enlaces */
  font-weight: bold; /* Texto en negrita */
  margin-bottom: 10px; /* Espacio entre enlaces */
  transition: background-color 0.3s; /* Transición suave para el efecto hover */
}

.menu a:hover {
  background-color: #333333; /* Color de fondo al pasar el cursor */
}

.close-btn {
  position: absolute;
  top: 10px; /* Ajusta la distancia desde la parte superior */
  right: 10px; /* Ajusta la distancia desde la derecha */
  font-size: 1.8em;
  cursor: pointer;
  border: none;
  background: transparent;
  color: #fff;
}
.bars-btn{
  font-size: 2.5em;
  color: #000;
  align-self: start;
}
/* ... */
</style>
