import { createApp } from 'vue';
import App from './App.vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min'; // Asegúrate de que estás usando la versión correcta de Bootstrap
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Carousel as AppCarousel, Slide as AppSlide } from 'vue3-carousel';
import 'animate.css';
import 'vue3-carousel/dist/carousel.css';

library.add(faChevronRight, faChevronLeft,faBars, faXmark);
library.add(faWhatsapp, faArrowUp);

const app = createApp(App);

app.component('font-awesome-icon', FontAwesomeIcon);
app.component('AppCarousel', AppCarousel);
app.component('AppSlide', AppSlide);

app.mount('#app');
