<template>
    <div>
      <!-- Tu contenido aquí -->
  
      <!-- Botón de WhatsApp -->
      <a href="https://api.whatsapp.com/send?phone=5545938116&text=Me%20interesa%20saber%20mas%20de%20Milan%2044" class="whatsapp-button" target="_blank">
        <font-awesome-icon :icon="['fab', 'whatsapp']" />
      </a>
  
      <!-- Botón para subir -->
      <button class="scroll-top-button" @click="scrollToTop" v-if="isScrolled">
        <font-awesome-icon :icon="['fas', 'arrow-up']" />
      </button>
    </div>
  </template>
  
  <script>
  export default {
    name: 'BotonesFlotantes',
    data() {
      return {
        isScrolled: false,
      };
    },
    methods: {
      scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      },
      handleScroll() {
        this.isScrolled = window.scrollY > 100;
      },
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
      window.removeEventListener('scroll', this.handleScroll);
    },
  };
  </script>
  
  <style scoped>
  .whatsapp-button {
  position: fixed; /* Fija el botón en una posición en la pantalla */
  bottom: 20px; /* 20px desde la parte inferior de la pantalla */
  font-size: 2rem;
  left: 20px; /* 20px desde la parte izquierda de la pantalla */
  width: 50px; /* Ancho del botón */
  height: 50px; /* Altura del botón */
  border-radius: 50%; /* Hace que el botón sea un círculo */
  background-color: #b0b1b2; /* Color de fondo de WhatsApp */
  color: white; /* Color del ícono */
  display: flex; /* Utiliza flexbox para centrar el contenido */
  justify-content: center; /* Centra horizontalmente el contenido */
  align-items: center; /* Centra verticalmente el contenido */
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3); /* Sombra suave para el botón */
  text-decoration: none; /* Elimina el subrayado de los enlaces */
  z-index: 100; /* Asegura que el botón esté sobre otros elementos */
}
  
.scroll-top-button {
  position: fixed; /* Fija el botón en una posición en la pantalla */
  bottom: 20px; /* 20px desde la parte inferior de la pantalla */
  right: 20px; /* 20px desde la parte derecha de la pantalla */
  width: 50px; /* Ancho del botón */
  height: 50px; /* Altura del botón */
  border-radius: 10px; /* Bordes ligeramente redondeados */
  background-color: #f0f0f0; /* Color de fondo del botón */
  color: #333; /* Color del ícono */
  display: flex; /* Utiliza flexbox para centrar el contenido */
  justify-content: center; /* Centra horizontalmente el contenido */
  align-items: center; /* Centra verticalmente el contenido */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3); /* Sombra suave para el botón */
  cursor: pointer; /* Cambia el cursor para indicar que es un botón */
  z-index: 100; /* Asegura que el botón esté sobre otros elementos */
}
  </style>
  