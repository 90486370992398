<template>
    <div class="map-container">
      <iframe
        width="95%"
        height="450"
        style="border:0"
        loading="lazy"
        allowfullscreen
        referrerpolicy="no-referrer-when-downgrade"
        :src="mapSrc">
      </iframe>
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        mapSrc: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d940.6511562273819!2d-99.15740869198784!3d19.429481712598196!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1ff3280b62d05%3A0x4edefbde5d6ef7a0!2sC.%20Milan%2044%2C%20Ju%C3%A1rez%2C%20Cuauht%C3%A9moc%2C%2006600%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX!5e0!3m2!1ses-419!2smx!4v1703016999000!5m2!1ses-419!2smx' // Reemplaza [YourEmbedCodeHere] con tu código de incorporación.
      };
    }
  };
  </script>
  