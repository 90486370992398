<template>
    <div>
        <section class='fondoblanco'>
            <div class="container header">
                <div class="row">
                    <div class="col-12">
                        <div class='titulo-principal '>
                            <span class='color-negro'>MILÁN</span>
                            <span class='color-verde'> 44</span>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class='titulo-direccion'>
                            <span class='color-gris'>COL. JUÁREZ</span>
                        </div>
                    </div> 
                </div>
            </div>
        </section>
        <!-- <section >
            <div class="container etiquetas">
                <div class="row">
                    <div class="col-md-6 col-12">
                        <span class='etiquetas-rapidas'>Comercio y espacio de trabajo</span>
                        <span class='etiquetas-rapidas'>Espacios disponibles</span>
                        <span class='etiquetas-rapidas'>Terminado</span>
                    </div>
                    <div class="col-md-6 col-12">

                    </div>
                </div>
            </div>
        </section> -->
        <section class='conteiner-fluid img-principal '>
        </section>
        <section class='container-fluid info-principal pb-5' id='proyecto'>
            <div class='container'>
                <div class="row" ref="filaProyecto" id='filaProyecto' :class="animateClass" >
                    <!-- En pantallas medianas y grandes, esto tomará 6 columnas, y en pantallas pequeñas, tomará el ancho completo -->
                    <div class="col-md-2 col-12">
                        <h2 class='titulo-secundario'>EL PROYECTO</h2>
                    </div>
                    <!-- En pantallas medianas y grandes, esto también tomará 6 columnas, y en pantallas pequeñas, tomará el ancho completo -->
                    <div class="col-md-10 col-12">
                        <p class='texto-proyectos'>Ubicado en el corazón de la histórica colonia Juárez, Milán 44 es un proyecto vertical de usos mixtos que parte del rescate de una estructura preexistente.</p>
                    </div>
                    <div class="col-12">
                        <div class="row imagenes-proyecto">
                            <div class="col-md-6 col-12" ref="columnaProyectoimg" id='columnaProyectoimg' :class="animateClass">
                                <img class='img-proyecto' :src="require('@/assets/img/FLOTANTE1_Milan44.webp')" alt="Descripción de la imagen">
                            </div>
                            <div class="col-md-6 col-12 imagen-proyectos1" ref='columnaProyectoimgdos' id='columnaProyectoimgdos' :class="animateClass">
                                <img class='img-proyecto' :src="require('@/assets/img/FLOTANTE_Milan44.webp')" alt="Descripción de la imagen">
                            </div>
                        </div>
                    </div>
                    <div class="col-12 carruselUno"  id='galeria' :class="animateClass">
                        <div class="row">
                            <h2 class='titulo-centro'>Galería</h2>
                            <div class="col-12">
                              <carruselMilan />
                            </div>
                        </div>
                    </div>
                    <detallesMilan ref='detalles' id='detalles' :class="animateClass" />
                    <citalMilan />
                    <contactoMIlan ref='contacto' id='contacto'  :class="animateClass" />
       
                    <div class="col-12">
                        <div class="row">
                            <div class="col-12">
                              
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class='conteiner-fluid img-principaldos'>
        </section>
        <div class='fondo texto-contactodos' >
            <div class="col-12 container">
                <div class="row">
                        <div class="col-12" ref='columnaProyectocarru' id='columnaProyectocarru' :class="animateClass" >
                            <carruseldosMilan />
                        </div>
                        <div class="col-12" ref='columnaProyectobfinal' id='columnaProyectobfinal' :class="animateClass" >
                        <bannerFinal  class='banerfinal' id='contactodos' />
                        </div>
                </div>
                <div class="row direccionFinal" ref='filadirec' id='filadirec' :class="animateClass" >
                    <div class="col-12">
                        <h1 class='titulocuatro'>Renta en Milán 44</h1>
                        <p class='direccioncuatro'>Dirección: Milán 44, Col. Juárez, Cuauhtémoc, 06600, Ciudad de México</p>
                        <p class='direccioncuatro'>Tel: 55-4593-8116</p>
                    </div>
                </div>
            </div>
        </div>
                    
        
    </div>
</template>
 <script>
  import carruselMilan from './carruselMilan.vue';
  import detallesMilan from './detallesMilan.vue';
  import citalMilan from './citalMilan.vue';
  import contactoMIlan from './contactoMIlan.vue';
  import carruseldosMilan from './carruseldosMilan.vue';
  import bannerFinal from './bannerFinal.vue';
  
  export default {
    name: 'LandingPage',
    components: {
      carruselMilan,
      detallesMilan,
      citalMilan,
      contactoMIlan,
      carruseldosMilan,
      bannerFinal,
    },
    data() {
      return {
        titulo: "Milan 44",
        animateStatus: {
          filaProyecto: false,
          columnaProyectoimg: false,
          columnaProyectoimgdos: false,
          galeria: false,
          detalles: false,
          contacto: false,
          columnaProyectocarru: false,
          columnaProyectoFinal: false,
          filadirec: false,
          // Agrega más propiedades para otras secciones
        },
      };
    },
    computed: {
      // Método para calcular las clases de animación basadas en la sección
      animateClass() {
        return (section) => {
          return {
            'animate__animated': true,
            'animate__fadeInUp': this.animateStatus[section],
          };
        };
      },
    },
    methods: {
      handleScroll() {
        // Actualiza el estado de la animación basado en la posición de scroll para cada sección
        this.checkAnimationForSection('filaProyecto', this.$refs.filaProyecto);
        this.checkAnimationForSection('columnaProyectoimg', this.$refs.columnaProyectoimg);
        this.checkAnimationForSection('columnaProyectoimgdos', this.$refs.columnaProyectoimgdos);        
        this.checkAnimationForSection('galeria', this.$refs.galeriaSection);
        this.checkAnimationForSection('detalles', this.$refs.detallesSection);
        this.checkAnimationForSection('contacto', this.$refs.contactoSection);
        this.checkAnimationForSection('columnaProyectocarru', this.$refs.columnaProyectocarru);
        this.checkAnimationForSection('columnaProyectoFinal', this.$refs.columnaProyectoFinal);
        this.checkAnimationForSection('filadirec', this.$refs.filadirecSection);

        // Agrega llamadas para otras secciones aquí...
      },
      checkAnimationForSection(sectionName, sectionRef) {
        if (sectionRef) {
        const windowHeight = window.innerHeight;
        const scrollY = window.scrollY;
        const sectionTop = sectionRef.offsetTop;
        const sectionBottom = sectionTop + sectionRef.offsetHeight;
        const scrollPosition = scrollY + windowHeight;

        if (scrollPosition > sectionTop && scrollPosition < sectionBottom) {
          this.animateStatus[sectionName] = true;
        } else {
          this.animateStatus[sectionName] = false; // Opcional: reinicia la animación cuando la sección sale de la vista
        }
      }
      },
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
      window.removeEventListener('scroll', this.handleScroll);
    },
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  body{
    margin: 0;
    padding: 0;
    background-color: #b0b1b2;
  }
  .fondoblanco{
    background-color: #FFFFFF;
  }
 .color-negro{
    color: #000000;
 }
 .color-verde{
    color:#D7E025
 }
 .color-gris{
    color: #DEDEDE;
 }
 .fondo{
    background-color: #b0b1b2;
 }
 .titulo-principal{
    font-family: "Arial", sans-serif;
    font-size: 35px;
    text-align: left;
    line-height: 1.1; /* Ajusta el espaciado entre líneas */
    margin-bottom: 0; /* Elimina el margen inferior para acercar al siguiente bloque de texto */
}
.titulo-direccion{
    font-family: "Arial", sans-serif;
    font-size: 35px;
    text-align: left;
    line-height: 1.1;
    margin-top: 0; /* Elimina el margen superior para acercar al bloque de texto anterior */
}
.etiquetas{
    margin-top: 35px;
}
.etiquetas-rapidas{
    background-color: #F2F2F2;
    color: #000000;
    padding: 5px 15px 5px 15px;
    margin: 3px 3px 3px 3px;
    border-style: solid;
    border-color: rgba(2, 1, 1, 0);
    border-radius: 20px 20px 20px 20px;
    display: inline-block; /* Esto asegura que el span se comporte como un bloque inline y respete el white-space */
    white-space: nowrap; /* Evita que el texto se rompa en múltiples líneas */  
}
.etiquetas-rapidas:hover{
    background-color: #54595F;
    color: #FFFFFF;
}
.img-principal {
  background-image: url('~@/assets/img/PRINCIPAL_Milan44.webp');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 684px;
}
.img-principaldos {
  background-image: url('~@/assets/FINAL_Milan44.webp');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 584px;
  width: 100%;
  background-color: #b0b1b2;
}
.info-principal{
    font-family: "Arial", sans-serif;
    font-size: 1rem;
    color: #212529;
    line-height: 1.5;
    text-align: left;
    background-color: #b0b1b2;
    padding-top: 5%;
}
.titulo-secundario{
    color: #54595F;
    font-family: "Arial", sans-serif;
    font-size: 25px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    margin-top: 45px;
    margin-bottom: 45px;
}
.texto-proyectos{
    margin: 35px;
    text-align: center;
    color: #54595F;
    font-family: "Arial", sans-serif;
    font-size: 25px;
    font-weight: 300;
    line-height: 1.6em;
    letter-spacing: 0.9px;
}
.imagenes-proyecto{
    margin-top: 10%;
}
.imagen-proyectos1{
    padding-top: 2%;
}
.imagenes-proyecto{
    margin-bottom: 15%;
}
.img-proyecto{
    width: 98%;
}
.titulo-centro{
    text-align: center;
    color: #000000;
    font-family: "Arial", sans-serif;
    font-size: 35px;
    text-align: center;
    margin-bottom: 5%;
}
.carruselUno{
    margin-bottom: 15%;
}

@media (max-width: 767px) {
  .img-principal {
    min-height: 45vh; /* Altura basada en el alto de la ventana para pantallas pequeñas */
  }
  .titulo-secundario{
    margin-top: 35px;
    margin-bottom: 35px;
  }
  .texto-proyectos{
    margin-top: 35px;
    margin: 5px;
  }
  .imagen-proyectos1{
    padding-top: 10%;
}
.img-principaldos {
  min-height: 284px;
  width: 100%;
  background-color: #b0b1b2;
}
}
.banerfinal{
    padding-top: 80px;
    padding-bottom: 80px;
    min-height: 350px;
}
.titulocuatro{
    font-family: "Arial", sans-serif;
    font-size: 40px;
    text-align: center;
    line-height: 1.1;
    margin-top: 0; /* Elimina el margen superior para acercar al bloque de texto anterior */
    color: #000000;
}
.direccioncuatro{
    text-align: center;
    font-size: 20px;
    color: #000000;
}
.direccionFinal{
    padding-top: 10%;
    padding-bottom:  10%;
}
  </style>
  