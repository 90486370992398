<template>
    <div class="opportunity-container">
      <h2>Discutamos esta oportunidad</h2>
      <p>¿Te interesa explorar opciones para rentar o comprar en Milán 44? </p>
      <p>¡Unamos esfuerzos!</p>
      <div>
        <a class='btn btn-outline-secondary btn-redondo' href="mailto:bennyturquie@gmail.com?subject=Interés en Discutir Oportunidades&body=Estoy interesado en discutir oportunidades de inversión. Por favor contáctame." >Haz clic para enviar email</a>
      </div>
    </div>
</template>
  
  <script>
  export default {
    methods: {
      startProcess() {
        // Aquí iría la lógica para iniciar el proceso (e.g., mostrar un formulario)
        console.log("Proceso iniciado");
      },
    },
  };
  </script>
  
  <style scoped>
  .opportunity-container {
    background-color: #b0b1b2;
    padding: 2rem;
    text-align: center;
  }
  
  h2 {
    margin-bottom: 1rem;
  }
  .btn-redondo{
    border-radius: 20px;
  }
  
  </style>
  