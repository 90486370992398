<template>
    <footer class="footer-section" style="background-color: #b0b1b2;">
      <div class="container">
        <div class="row">
          <!-- About Us Column -->
          <div class="col-md-4">
            <h5 class='titulo-direccion'>Dirección</h5>
            <p class='direccion'>Milán 44, Col. Juárez, Cuauhtémoc, 06600, Ciudad de México</p>
          </div>
          <!-- Quick Links Column -->
          <div class="col-md-4">
            <h5 class='titulo-direccion'>Secciones</h5>
            <ul class="list-unstyled">
              <li class='seccions' ><a href="#proyecto">Proyecto</a></li>
              <li class='seccions' ><a href="#galeria">Galería</a></li>
              <li class='seccions' > <a href="#detalles">Detalles</a></li>
              <li class='seccions' ><a href="#contacto">Contacto</a></li>
              <li class='seccions' ><a href="#contactodos">Enviar Correo</a></li>
            </ul>
          </div>
          <!-- Contact Column -->
          <div class="col-md-4">
            <h5 class='titulo-direccion'>Contacto</h5>
            <p class='seccions'>Tel: 55-4593-8116</p>
            <p class='seccions'>Email: crembo@crembo.net</p>
          </div>
        </div>
        <div class="row copy-container">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <img src="@/assets/NYDHOLDINGS.svg" class="logo" alt="Logo">
              </div>
            </div>
          </div>
          
        <span class="copy">© Copyright NYD Holdings 2023 | Todos los derechos reservados | Políticas de privacidad | Creado por mktska</span>
      </div>
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    name: "FooterSection",
    // Vue-specific options here
  }
  </script>
  
  <style scoped>
.footer-section {
  background-color: #b0b1b2; /* Color de fondo */
  padding-top: 3rem !important; /* Espaciado superior */
  padding-bottom: 0; /* Remover el espaciado inferior para que el copyright esté pegado al fondo */
  color: #ffffff; /* Color del texto */
}

.container {
  display: flex;
  flex-direction: column; /* Organiza los hijos de container en una columna */
  justify-content: space-between; /* Espacia los hijos de container */
  height: 100%; /* Altura completa para empujar el copy al fondo */
}

.row {
  padding: 0.5rem 0; /* Espaciado vertical para las filas */
}

.copy-container {
  margin-top: auto; /* Empuja el contenedor de copy al fondo */
  /* Línea superior */
}

.copy {
  display: block; /* Hace que el span se comporte como un bloque */
  width: 100%; /* Ancho completo para centrar el texto */
  font-size: 0.75rem; /* Tamaño de la fuente más pequeño */
  color: #000; /* Color del texto */
  text-align: center; /* Centrado de texto */
  padding: 1rem 0; /* Espaciado para el área de copy */
}
  .footer-section {
    padding: 2rem 0;
    color: #000;
    border-top: 1px solid #000;
    /* Add additional styling as needed */
    min-height: 250px;
  }
  .copy{
    font-size: 12px;
    color: #000;
    text-align: center;
}
  .seccions{
    font-size: 15px;
    color: #000;
    text-align: left;
  }
  .seccions a{
    color: #000;
  }
  .titulo-direccion{
    font-family: "Arial", sans-serif;
    font-size: 35px;
    text-align: left;
    line-height: 1.1;
    margin-top: 0; /* Elimina el margen superior para acercar al bloque de texto anterior */
  }
  .direccion{
    text-align: left;
    font-size: 20px;
  }
  .logo{
    width: 100px;
    height: 100px;
    margin-right: 5%;
  }
  @media (max-width: 767px) {
    .titulo-direccion{
    font-family: "Arial", sans-serif;
    font-size: 25px;
    text-align: left;
    line-height: 1.1;
    margin-top: 0; /* Elimina el margen superior para acercar al bloque de texto anterior */
  }
  .direccion{
    text-align: left;
    font-size: 15px;
  }
  .seccions{
    font-size: 15px;
    color: #000;
    text-align: left;
  }
  }
  /* You can add more styles here for typography, links, etc. */
  </style>