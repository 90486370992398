<template>
  <div class='contenedorprincipal container-fluid'>
    <menuMilan/>
    <LandingPage/>
    <FooterSection/>
    <BotonesFlotantes/>
  </div>
</template>

<script>
import LandingPage from './components/LandingPage.vue';
import menuMilan from './components/menuMilan.vue';
import FooterSection from './components/footerMilan.vue';
import BotonesFlotantes from './components/botonesFlotantes.vue';

export default {
  name: 'App',
  components: {
    LandingPage,
    menuMilan,
    FooterSection,
    BotonesFlotantes
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000;
  
}
.contenedorprincipal{
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin: 0 !important;
}
</style>
