<template>
    <div class='contenedormenu'>
        <nav class="navbar navbar-expand-lg navbar-light bg-light d-flex">
            <div class="container-fluid align-middle">
                <navbarMIlan class='barras'/> <!--Este es el componente de las barras-->
                <img src="@/assets/NYDHOLDINGS.svg" class="navbar-logo2" alt="Logo">
                <div class="collapse navbar-collapse container" id="navbarTogglerDemo03">
                    <img src="@/assets/NYDHOLDINGS.svg" class="navbar-logo" alt="Logo">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item">
                        <a class="nav-link" href="#proyecto">Proyecto</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#galeria">Galería</a>
                    </li>
                    <li class="nav-item">
                        <a  class="nav-link" href="#detalles">Detalles</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#contacto">Contacto</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#contactodos">Enviar Correo</a>
                    </li>
                </ul>
                </div>
            </div>
        </nav>
    </div>
</template>
<script>
import navbarMIlan from './navbarMIlan.vue';

export default {
  name: 'menuMilan',
  components: {
    navbarMIlan
  }
}
</script>
<style scoped>
.barras{
    margin-right: 10px;
    margin-left: 20px;
}
.contenedormenu {
  margin-bottom: 60px;
}
.navbar-logo {
  width: 100px;
  height: 100px;
  margin-right: 5%;
}
.navbar {
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 0; /* Elimina el padding predeterminado si es necesario */
}

.navbar-nav {
  list-style: none; /* Elimina los estilos de lista */
  padding: 0; /* Elimina el padding predeterminado si es necesario */
  margin: 0; /* Elimina el margen predeterminado si es necesario */
  display: flex; /* Dispone los elementos en línea */
  align-items: center; /* Alinea verticalmente los elementos */
}

.nav-item {
  margin: 0 10px; /* Agrega un poco de espacio entre los enlaces */
}

.nav-link {
  display: inline-block;
  position: relative;
  text-decoration: none; /* Elimina el subrayado */
  color: #000; /* Color de texto negro */
  padding-bottom: 5px; /* Espacio debajo del texto para la línea */
}

.nav-link:hover,
.nav-link:focus {
  text-decoration: none; /* Asegúrate de que el subrayado no reaparezca al pasar el mouse */
}

.nav-link::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #000; /* Color de la línea */
  transition: width .3s;
}

.nav-link:hover::after,
.nav-link:focus::after {
  width: 100%; /* La línea se extiende al pasar el mouse o enfocar */
}

/* Para el enlace activo, puedes agregar una clase 'active' o usar Vue Router's class 'router-link-exact-active' */
.nav-link.active::after {
  width: 100%; /* La línea es siempre visible para el enlace activo */
}
.navbar-logo2 {
  width: 100px;
  height: 100px;
  margin-right: 5%;
  display: none;
}
@media (max-width: 767px) {
  .navbar-logo2 {
    display: block;
  width: 80px;
  height: auto;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 5%;
}
  }
</style>